import { Moment } from "moment";
import { ProjectRatingsPageType, ProjectsType, ProjectRatingType } from "types/project.types";

export const pageSize = 10;
const ACCESS_TOKEN_KEY = "ACCESS_TOKEN_KEY";

class API {
  static apiUrl = process.env.REACT_APP_API_URL;

  async getProjects(startTime: Moment, endTime: Moment): Promise<ProjectsType | null> {
    300;
    const response = await this.get(
      `/project` + `?startTime=${startTime.toISOString()}` + `&endTime=${endTime.toISOString()}`,
    );

    if (response.ok) {
      return await response.json();
    }

    return null;
  }

  async getProjectRatingsPage(
    projectId: string,
    startTime: Moment,
    endTime: Moment,
    skip: number,
  ): Promise<ProjectRatingsPageType | null> {
    const response = await this.get(
      `/project/${projectId}/ratings` +
        `?startTime=${startTime.toISOString()}` +
        `&endTime=${endTime.toISOString()}` +
        `&take=${pageSize}` +
        `&skip=${skip}`,
    );

    if (response.ok) {
      return await response.json();
    }

    return null;
  }

  async deleteRating(ratingId: string): Promise<boolean> {
    const response = await this.delete(`/rating/${ratingId}`);
    return response.ok;
  }

  async deleteProject(projectId: string): Promise<boolean> {
    const response = await this.delete(`/project/${projectId}`);
    return response.ok;
  }
  async respondToRating(ratingId: string, message: string): Promise<boolean> {
    const response = await this.post(`/rating/${ratingId}/respond`, {
      response: message,
    });

    return response.ok;
  }

  async editRating({
    id,
    createdAt,
    response: ratingResponse,
    // @ts-ignore
    project,
    ...props
  }: ProjectRatingType) {
    const response = await this.put(`/rating/${id}`, props);
    return response.ok;
  }

  async isAuthValid(): Promise<boolean> {
    const response = await this.patch("/rating/404", {});

    return response.status !== 401;
  }

  public async login(accessToken: string): Promise<boolean> {
    this.setAccessToken(accessToken);

    const isAuthValid = await this.isAuthValid();
    if (!isAuthValid) {
      this.removeAccessToken();
    }

    return isAuthValid;
  }

  async get(endpoint: string): Promise<Response> {
    return fetch(`${API.apiUrl}${endpoint}`, {
      method: "GET",
      headers: this.headers,
    });
  }

  async post(endpoint: string, body: object): Promise<Response> {
    return fetch(`${API.apiUrl}${endpoint}`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(body),
    });
  }

  async put(endpoint: string, body: object): Promise<Response> {
    return fetch(`${API.apiUrl}${endpoint}`, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(body),
    });
  }

  async patch(endpoint: string, body: object): Promise<Response> {
    return fetch(`${API.apiUrl}${endpoint}`, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify(body),
    });
  }

  async delete(endpoint: string): Promise<Response> {
    return fetch(`${API.apiUrl}${endpoint}`, {
      method: "DELETE",
      headers: this.headers,
    });
  }

  private getAccessToken(): string {
    return localStorage.getItem(ACCESS_TOKEN_KEY) || "";
  }

  private setAccessToken(accessToken: string) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  }

  private removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  private get headers(): HeadersInit {
    return { "Content-Type": "application/json", "x-ratings-key": this.getAccessToken() };
  }

  public get isLoggedIn(): boolean {
    return !!this.getAccessToken();
  }

  public logout() {
    this.removeAccessToken();
  }
}

export const api = new API();
