import React, { FormEvent, useState } from "react";
import { Form, Input, Button } from "antd";
import { FormComponentProps } from "antd/lib/form";

const ResponseFormKeys = {
  MESSAGE: "message",
};

type ResponseFormProps = FormComponentProps & {
  onSend(response: string): void;
  sending: boolean;
};

const ResponseFormDisconnected: React.FC<ResponseFormProps> = ({
  form: { getFieldDecorator, validateFields, isFieldsTouched },
  onSend,
  sending,
}) => {
  const [isTouched, setTouched] = useState(false);

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        onSend(values[ResponseFormKeys.MESSAGE]);
      }
    });
  };

  const onChangeHandler = () => {
    validateFields(err => {
      if (!err) {
        setTouched(isFieldsTouched());
      } else {
        setTouched(false);
      }
    });
  };

  return (
    <Form onSubmit={onSubmitHandler} onChange={onChangeHandler}>
      <Form.Item>
        {getFieldDecorator(ResponseFormKeys.MESSAGE, {
          rules: [
            {
              required: true,
              type: "string",
              message: "Voer een antwoord in.",
            },
          ],
        })(<Input.TextArea placeholder="Antwoord" />)}
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={sending} disabled={!isTouched}>
        Versturen
      </Button>
    </Form>
  );
};

export const ResponseForm = Form.create({})(ResponseFormDisconnected);
