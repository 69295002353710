import { Button, Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { css } from "emotion";
import React, { FormEvent } from "react";

const LoginFormKeys = {
  ACCESS_CODE: "access_code",
};

type LoginPageProps = FormComponentProps & {
  onLogin(accessCode: string): void;
};

const LoginFormDisconnected: React.FC<LoginPageProps> = ({
  form: { getFieldDecorator, validateFields },
  onLogin,
}) => {
  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        onLogin(values[LoginFormKeys.ACCESS_CODE]);
      }
    });
  };

  return (
    <Form
      onSubmit={onSubmitHandler}
      className={css`
        width: 100%;
        max-width: 280px;
      `}
    >
      <Form.Item>
        {getFieldDecorator(LoginFormKeys.ACCESS_CODE, {
          rules: [{ required: true, message: "Voer de token in." }],
        })(<Input placeholder="Token" />)}
      </Form.Item>
      <Button type="primary" block={true} htmlType="submit">
        Ontgrendelen
      </Button>
    </Form>
  );
};

export const LoginForm = Form.create({})(LoginFormDisconnected);
