import React, { useState } from "react";
import { ModalButton } from "components/molecules/buttons/modal-button";
import { RatingForm } from "components/molecules/forms/rating-form";
import { ProjectRatingType } from "types/project.types";
import { api } from "api";

type EditRatingControllerProps = {
  rating: ProjectRatingType;
  onEditSuccessful(): void;
};

export const EditRatingController: React.FC<EditRatingControllerProps> = ({
  rating,
  onEditSuccessful,
}) => {
  const [loading, setLoading] = useState(false);

  const onSaveHandler = async (newRating: ProjectRatingType) => {
    setLoading(true);
    const isSuccessful = await api.editRating(newRating);

    if (isSuccessful) {
      onEditSuccessful();
    }

    setLoading(false);
  };

  return (
    <ModalButton title="Bewerken" label="Bewerken" modalProps={{ width: 800 }}>
      <RatingForm rating={rating} onSave={onSaveHandler} saving={loading} />
    </ModalButton>
  );
};
