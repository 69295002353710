import React from "react";
import { css } from "emotion";
import { string } from "prop-types";
import { colors } from "components/shared/colors";

type ContactData = {
  name?: string;
  email?: string;
  phone?: string;
  organization?: string;
};

const transformData = (contact: ContactData) =>
  Object.keys(contact).reduce<
    Array<{
      label: string;
      value: string | number;
    }>
  >((accumulator, propName) => {
    switch (propName) {
      case "name":
        accumulator.push({
          label: "Naam",
          value: contact[propName] as string | number,
        });
        break;
      case "email":
        accumulator.push({
          label: "E-mail",
          value: contact[propName] as string | number,
        });
        break;
      case "organization":
        accumulator.push({
          label: "Organisatie",
          value: contact[propName] as string | number,
        });
        break;
      case "phone":
        accumulator.push({
          label: "Telefoonnummer",
          value: contact[propName] as string | number,
        });
        break;
    }

    return accumulator;
  }, []);

const itemStyle = css`
  width: 50%;
  height: 38px;
  text-align: left;
  padding: 10px;

  &:not(:nth-last-child(1)):not(:nth-last-child(2)) {
    border-bottom: 1px solid ${colors.MERCURY};
  }

  &:nth-child(2n-1) {
    border-right: 1px solid ${colors.MERCURY};
  }
`;

type ContactTableProps = {
  data: ContactData;
};

export const ContactTable: React.FC<ContactTableProps> = ({ data }) => {
  const transformedData = transformData(data);
  return (
    <div
      className={css`
        display: flex;
        flex-wrap: wrap;
        background-color: white;
        border: 1px solid ${colors.MERCURY};
        border-image: initial;
        border-radius: 4px;
      `}
    >
      {transformedData.map(item => [
        <div key={`label-${item.label}`} className={itemStyle}>
          <strong>{item.label}</strong>
        </div>,
        <div key={`value-${item.value}`} className={itemStyle}>
          {item.value}
        </div>,
      ])}
    </div>
  );
};
