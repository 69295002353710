import { ProductContainer } from "components/atoms/product-container";
import { ProductTitle } from "components/molecules/product-title";
import { Moment } from "moment";
import React from "react";

import { ProductReviewsController } from "./controllers/product-reviews-controller";

type ProductProps = {
  id: string;
  name: string;
  ratingAverage: number;
  ratingCount: number;
  startDate: Moment;
  endDate: Moment;
  onDeleteSuccessful: () => void;
};

export const Product: React.FC<ProductProps> = ({
  id,
  name,
  ratingAverage,
  ratingCount,
  startDate,
  endDate,
  onDeleteSuccessful,
}) => {
  return (
    <ProductContainer>
      <ProductTitle
        name={name}
        ratingAverage={ratingAverage}
        ratingCount={ratingCount}
        onDeleteSuccessful={onDeleteSuccessful}
        projectId={id}
      />
      <ProductReviewsController id={id} startDate={startDate} endDate={endDate} />
    </ProductContainer>
  );
};
