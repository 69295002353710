import { Button, Col, Form, Row, Card } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { css } from "emotion";
import React, { FormEvent, useState } from "react";
import { ProjectRatingType } from "types/project.types";

import { RatingDetailsFormGroup, RatingDetailsFormKeys } from "./groups/rating-details-form-group";
import { UserDetailsFormGroup, UserDetailsFormKeys } from "./groups/user-details-form-group";
import { colors } from "components/shared/colors";

type RatingFormProps = FormComponentProps & {
  rating: ProjectRatingType;
  saving: boolean;
  onSave(rating: ProjectRatingType): void;
};

export const RatingFormDisconnected: React.FC<RatingFormProps> = ({
  form,
  rating,
  saving,
  onSave,
}) => {
  const { validateFields, isFieldsTouched } = form;

  const [isTouched, setTouched] = useState(false);

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const newRating = Object.assign({}, rating);
        newRating[RatingDetailsFormKeys.COMMENT] =
          values[RatingDetailsFormKeys.COMMENT] || undefined;
        newRating[RatingDetailsFormKeys.RATING] = values[RatingDetailsFormKeys.RATING] || undefined;
        newRating[RatingDetailsFormKeys.IMPORTANCE] =
          values[RatingDetailsFormKeys.IMPORTANCE] || undefined;
        newRating.contact.name = values[UserDetailsFormKeys.NAME] || undefined;
        newRating.contact.email = values[UserDetailsFormKeys.EMAIL] || undefined;
        newRating.contact.organization = values[UserDetailsFormKeys.ORGANIZATION] || undefined;
        newRating.contact.phone = values[UserDetailsFormKeys.PHONE] || undefined;

        console.log(rating, newRating);
        onSave(newRating);
      }
    });
  };

  const onChangeHandler = () => {
    setTouched(isFieldsTouched());
  };

  return (
    <Form
      labelCol={{ xs: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 } }}
      labelAlign="left"
      onSubmit={onSubmitHandler}
      onChange={onChangeHandler}
    >
      <Row>
        <Col span={12}>
          <Card title="Contactgegevens" size="small" bordered={false}>
            <UserDetailsFormGroup form={form} rating={rating} />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Beoordeling" size="small" bordered={false}>
            <RatingDetailsFormGroup form={form} rating={rating} onChangeHook={onChangeHandler} />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item wrapperCol={{ xs: { span: 24 } }}>
            <div
              className={css`
                display: flex;
                justify-content: flex-end;
              `}
            >
              <Button type="primary" htmlType="submit" loading={saving} disabled={!isTouched}>
                Opslaan
              </Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export const RatingForm = Form.create({})(RatingFormDisconnected);
