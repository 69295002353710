import { Typography } from "antd";
import { PaginationConfig } from "antd/lib/table";
import { api, pageSize } from "api";
import { ContactTable } from "components/molecules/contact-table";
import { ModalButton } from "components/molecules/buttons/modal-button";
import { ReviewsTable } from "components/molecules/reviews-table";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { ProjectRatingsPageType } from "types/project.types";
// @ts-ignore
import Truncate from "react-truncate";

import { ResponseController } from "./response-controller";
import { DeleteRatingController } from "./delete-rating-controller";
import { EditRatingController } from "./edit-rating-controller";

type ProductReviewControllerProps = {
  id: string;
  startDate: Moment;
  endDate: Moment;
};

export const ProductReviewsController: React.FC<ProductReviewControllerProps> = ({
  id,
  startDate,
  endDate,
}) => {
  const [ratingsPage, setRatingsPage] = useState<ProjectRatingsPageType | null>();
  const [skipItemsCount, setSkipItemsCount] = useState(0);
  const [shouldFetch, setShouldFetch] = useState(true);

  const fetchData = () => {
    api.getProjectRatingsPage(id, startDate, endDate, skipItemsCount).then(fetchedRatingsPage => {
      setRatingsPage(fetchedRatingsPage);
      setShouldFetch(false);
    });
  };

  useEffect(() => {
    if (shouldFetch) {
      fetchData();
    }
  }, [skipItemsCount, shouldFetch]);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  if (!ratingsPage) {
    return null;
  }

  const onTableChangeHandler = ({ current }: PaginationConfig) => {
    if (current) {
      const itemsToSkip = (current - 1) * pageSize;
      setSkipItemsCount(itemsToSkip);
      setShouldFetch(true);
    }
  };

  const onDeleteSuccessfulHandler = () => {
    setShouldFetch(true);
  };
  const onEditSuccessfulHandler = () => {
    setShouldFetch(true);
  };

  const currentPage = Math.ceil((skipItemsCount + pageSize) / pageSize);

  return (
    <ReviewsTable
      dataSource={ratingsPage.ratings.map(rating => ({
        key: rating.id,
        actions: [
          <DeleteRatingController
            key="delete"
            ratingId={rating.id}
            onDeleteSuccessful={onDeleteSuccessfulHandler}
          />,
          <EditRatingController
            key="edit"
            rating={rating}
            onEditSuccessful={onEditSuccessfulHandler}
          />,
        ],
        date: moment(rating.createdAt),
        comment: rating.ratingText && (
          <Truncate
            lines={2}
            width={300}
            ellipsis={
              <>
                ...
                <ModalButton title="Opmerkingen" label=" Meer">
                  {rating.ratingText}
                </ModalButton>
              </>
            }
          >
            {rating.ratingText}
          </Truncate>
        ),
        experienceRating: rating.ratingNumber,
        importanceRating: rating.importanceNumber,
        contact: Object.keys(rating.contact).length ? (
          <ModalButton label="Details" title="Details gebruiker">
            <ContactTable data={rating.contact} />
          </ModalButton>
        ) : (
          <Typography.Text disabled={true}>Niet opgegeven</Typography.Text>
        ),
        response: (rating.response && rating.response.response && (
          <Truncate
            lines={2}
            width={300}
            ellipsis={
              <>
                ...
                <ModalButton title="Antwoord" label=" Meer">
                  {rating.response.response}
                </ModalButton>
              </>
            }
          >
            {rating.response.response}
          </Truncate>
        )) || <ResponseController ratingId={rating.id} />,
      }))}
      pagination={{
        current: currentPage,
        total: parseInt(ratingsPage.pagination.total),
        pageSize,
        defaultCurrent: 1,
      }}
      onChange={onTableChangeHandler}
    />
  );
};
