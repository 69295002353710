import { Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import React from "react";
import { ProjectRatingType } from "types/project.types";

export const UserDetailsFormKeys = {
  NAME: "name",
  EMAIL: "email",
  ORGANIZATION: "organization",
  PHONE: "phone",
};

type UserDetailsFormProps = FormComponentProps & {
  rating: ProjectRatingType;
  onChangeHook?(): void;
};

export const UserDetailsFormGroup: React.FC<UserDetailsFormProps> = ({
  form: { getFieldDecorator },
  rating,
  onChangeHook,
}) => {
  return (
    <>
      <Form.Item label="Naam">
        {getFieldDecorator(UserDetailsFormKeys.NAME, {
          initialValue: rating.contact.name,
        })(<Input placeholder="Naam" />)}
      </Form.Item>

      <Form.Item label="E-mail">
        {getFieldDecorator(UserDetailsFormKeys.EMAIL, {
          initialValue: rating.contact.email,
        })(<Input placeholder="E-mail" type="email" />)}
      </Form.Item>

      <Form.Item label="Organisatie">
        {getFieldDecorator(UserDetailsFormKeys.ORGANIZATION, {
          initialValue: rating.contact.organization,
        })(<Input placeholder="Organisatie" />)}
      </Form.Item>

      <Form.Item label="Telefoonnummer">
        {getFieldDecorator(UserDetailsFormKeys.PHONE, {
          initialValue: rating.contact.phone,
        })(<Input placeholder="Telefoonnummer" />)}
      </Form.Item>
    </>
  );
};
