import { DashboardController } from "modules/controllers/dashboard-controller";
import React from "react";

import { Layout } from "../modules/layout";

type DashboardPageProps = {};

export const DashboardPage: React.FC<DashboardPageProps> = () => (
  <Layout>
    <DashboardController />
  </Layout>
);
