import { Layout as AntdLayout } from "antd";
import { colors } from "components/shared/colors";
import { css } from "emotion";
import React from "react";
import brainbayLogo from "static/img/brainbay-logo.svg";

type HeaderProps = {
  authMenu?: React.ReactNode;
};

export const Header: React.FC<HeaderProps> = ({ authMenu }) => (
  <AntdLayout.Header
    className={css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}
  >
    <div>
      <img
        className={css`
          height: 48px;
        `}
        src={brainbayLogo}
      />
      <span
        className={css`
          --spacing: 12px;
          padding-left: var(--spacing);
          margin-left: var(--spacing);
          color: ${colors.WHITE};
          font-size: 1rem;
          line-height: 1.3rem;
          font-weight: 400;
          height: 20px;
        `}
      >
        Beoordelingssysteem
      </span>
    </div>
    <div>{authMenu}</div>
  </AntdLayout.Header>
);
