import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { LoginPage } from "./pages/login-page";
import { DashboardPage } from "./pages/dashboard-page";
import { injectGlobal, css } from "emotion";
import { LogoutPage } from "pages/logout-page";
import { HomePage } from "pages/home-page";
import { AuthRoute } from "routing/authRoute";

export class App extends Component<{}> {
  render() {
    injectGlobal(css`
      @import url("https://fonts.googleapis.com/css?family=Sarabun:400,500,700");
    `);

    return (
      <Router>
        <Route exact={true} path="/" component={HomePage} />
        <Route exact={true} path="/logout" component={LogoutPage} />
        <Route exact={true} path="/login" component={LoginPage} />
        <AuthRoute exact={true} path="/manage" component={DashboardPage} />
      </Router>
    );
  }
}
