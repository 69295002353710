import React, { useState } from "react";
import { Layout as AntdLayout, Menu } from "antd";
import { Header } from "../components/atoms/header";
import { css } from "emotion";
import { api } from "api";
import { colors } from "components/shared/colors";
import { AuthController } from "./controllers/auth-controller";

type Layout = React.ReactNode & {};

export const Layout: React.FC<Layout> = ({ children }) => {
  return (
    <AntdLayout>
      <Header authMenu={<AuthController />} />
      <AntdLayout.Content
        className={css`
          height: calc(100% - 64px);
          margin: 5%;
        `}
      >
        {children}
      </AntdLayout.Content>
    </AntdLayout>
  );
};
