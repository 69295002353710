import { Icon, Popconfirm, Spin } from "antd";
import { css } from "emotion";
import React, { useState } from "react";
import { api } from "api";

type DeleteRatingControllerProps = {
  projectId: string;
  onDeleteSuccessful(): void;
};

export const DeleteProjectController: React.FC<DeleteRatingControllerProps> = ({
  projectId,
  onDeleteSuccessful,
}) => {
  const [loading, setLoading] = useState(false);

  const onDeleteHandler = async () => {
    setLoading(true);
    api.deleteProject(projectId).then(successful => {
      if (successful) {
        onDeleteSuccessful();
      }
      setLoading(false);
    });
  };

  return (
    <div
      className={css`
        padding-left: 10px;
        padding-right: 10px;
      `}
    >
      {loading ? (
        <Spin />
      ) : (
        <Popconfirm
          title="Weet je zeker dat je dit project en de bijbehorende beoordelingen wilt verwijderen?"
          onConfirm={onDeleteHandler}
          okText="Ja"
          cancelText="Nee"
        >
          <Icon type="delete" theme="twoTone" twoToneColor="#cf1322" />
        </Popconfirm>
      )}
    </div>
  );
};
