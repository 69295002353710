import { Modal } from "antd";
import { css } from "emotion";
import React, { useState } from "react";
import { ModalProps } from "antd/lib/modal";

type ModalButtonProps = React.ReactNode & {
  title: string;
  label: string;
  modalProps?: ModalProps;
};

export const ModalButton: React.FC<ModalButtonProps> = ({ children, title, label, modalProps }) => {
  const [isVisible, setVisibility] = useState(false);

  return (
    <>
      <a
        href="javascript:;"
        onClick={() => setVisibility(true)}
        className={css`
          &:not(:first-of-type) {
            margin-left: 10px;
          }
        `}
      >
        {label}
      </a>
      <Modal
        title={title}
        visible={isVisible}
        footer={null}
        onCancel={() => setVisibility(false)}
        className={css`
          top: 25% !important;
        `}
        {...modalProps}
      >
        {children}
      </Modal>
    </>
  );
};
