import { api } from "api";
import { colors } from "components/shared/colors";
import { css } from "emotion";
import React, { useState } from "react";
import { Link } from "react-router-dom";

type AuthControllerProps = {};

export const AuthController: React.FC<AuthControllerProps> = () => {
  return api.isLoggedIn ? (
    <Link
      to="/logout"
      className={css`
        color: ${colors.WHITE};
      `}
    >
      Vergrendelen
    </Link>
  ) : null;
};
