import { api } from "api";
import React, { useEffect } from "react";
import { Route, RouteComponentProps, RouteProps, withRouter } from "react-router";

type AuthRouteProps = RouteProps & RouteComponentProps & {};

const AuthRouteDisconnected: React.FC<AuthRouteProps> = ({ component, history, ...props }) => {
  useEffect(() => {
    if (!api.isLoggedIn) {
      history.push("/login");
    }
  }, []);

  return component && api.isLoggedIn ? (
    <Route {...props} render={props => React.createElement(component, props)} />
  ) : null;
};

export const AuthRoute = withRouter(AuthRouteDisconnected);
