import React, { useEffect, useState } from "react";
import { DatePicker, Empty } from "antd";
import moment, { Moment } from "moment";
import { api } from "api";
import { ProjectsType } from "types/project.types";
import { Product } from "../product";
import { RangePickerValue } from "antd/lib/date-picker/interface";
import { DatePickerContainer } from "components/atoms/date-picker-container";

const DateRangeKeys = {
  START: 0,
  END: 1,
};

type DashboardControllerProps = {};

export const DashboardController: React.FC<DashboardControllerProps> = () => {
  const [dateRange, setDateRange] = useState<[Moment, Moment]>([
    moment().subtract(30, "days"),
    moment(),
  ]);
  const [projects, setProjects] = useState<ProjectsType | null>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .getProjects(dateRange[DateRangeKeys.START], dateRange[DateRangeKeys.END])
      .then(fetchedProjects => {
        setProjects(fetchedProjects);
        setLoading(false);
      });
  }, [dateRange]);

  const onDateRangeChangeHandler = (dates: RangePickerValue) => {
    if (dates[DateRangeKeys.START] && dates[DateRangeKeys.END]) {
      setDateRange([moment(dates[DateRangeKeys.START]), moment(dates[DateRangeKeys.END])]);
    }
  };

  const refreshReports = () => {
    setLoading(true);
    api
      .getProjects(dateRange[DateRangeKeys.START], dateRange[DateRangeKeys.END])
      .then(fetchedProjects => {
        setProjects(fetchedProjects);
        setLoading(false);
      });
  };

  if (!projects) {
    return null;
  }

  return (
    <>
      <DatePickerContainer>
        <DatePicker.RangePicker
          onChange={onDateRangeChangeHandler}
          defaultValue={dateRange}
          allowClear={false}
          separator="tot"
        />
      </DatePickerContainer>
      {!(projects.projects.length > 0) && <Empty />}
      {projects.projects.map(project => (
        <Product
          key={project.id}
          id={project.id}
          name={project.name}
          ratingAverage={project.ratingAverage}
          ratingCount={project.ratingCount}
          startDate={dateRange[DateRangeKeys.START]}
          endDate={dateRange[DateRangeKeys.END]}
          onDeleteSuccessful={refreshReports}
        />
      ))}
    </>
  );
};
