import { api } from "api";
import { ResponseForm } from "components/molecules/forms/response-form";
import { ModalButton } from "components/molecules/buttons/modal-button";
import React, { useEffect, useState } from "react";

type ResponseControllerProps = {
  ratingId: string;
};

export const ResponseController: React.FC<ResponseControllerProps> = ({ ratingId }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const onSendHandler = async (message: string) => {
    setLoading(true);
    api.respondToRating(ratingId, message).then(() => {
      setMessage(message);
      setLoading(false);
    });
  };

  return (
    <>
      {message || (
        <ModalButton label="Antwoorden" title="Antwoord versturen">
          <ResponseForm onSend={onSendHandler} sending={loading} />
        </ModalButton>
      )}
    </>
  );
};
