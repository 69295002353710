import React from "react";
import { css } from "emotion";

export const DatePickerContainer: React.FC<React.HTMLProps<HTMLDivElement>> = ({ ...props }) => (
  <div
    className={css`
      margin-bottom: 50px;
    `}
    {...props}
  />
);
