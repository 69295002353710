import React from "react";
import { api } from "api";
import { RouteComponentProps, Redirect } from "react-router";

type LogoutPageProps = RouteComponentProps & {};

export const LogoutPage: React.FC<LogoutPageProps> = ({}) => {
  api.logout();
  return <Redirect to="/" />;
};
