import { api } from "api";
import { LoginForm } from "components/molecules/forms/login-form";
import { css } from "emotion";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";

import { Layout } from "../modules/layout";
import { message } from "antd";

type LoginPageProps = RouteComponentProps & {};

export const LoginPage: React.FC<LoginPageProps> = ({ history }) => {
  useEffect(() => {
    if (api.isLoggedIn) {
      history.push("/manage");
    }
  }, []);

  const onLoginHandler = async (accessToken: string) => {
    const isAuthValid = await api.login(accessToken);
    if (!isAuthValid) {
      message.error("Voer een geldige token in");
    } else {
      history.push("/manage");
    }
  };

  return (
    <Layout>
      <section
        className={css`
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <LoginForm onLogin={onLoginHandler} />
      </section>
    </Layout>
  );
};
