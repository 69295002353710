import React from "react";
import { css } from "emotion";

export const ProductContainer: React.FC<React.HTMLProps<HTMLAreaElement>> = ({ ...props }) => (
  <article
    className={css`
      margin-top: 10px;
    `}
    {...props}
  />
);
