import React from "react";
import { Typography, Tag } from "antd";
import { css } from "emotion";
import { DeleteProjectController } from "modules/controllers/delete-project-controller";

type ProductTitleProps = {
  name: string;
  ratingCount: number;
  ratingAverage: number;
  projectId: string;
  onDeleteSuccessful: () => void;
};

export const ProductTitle: React.FC<ProductTitleProps> = ({
  name,
  ratingAverage,
  ratingCount,
  projectId,
  onDeleteSuccessful,
}) => (
  <div
    className={css`
      display: flex;
      align-items: center;
    `}
  >
    <DeleteProjectController projectId={projectId} onDeleteSuccessful={onDeleteSuccessful} />
    <div
      className={css`
        padding-top: 5px;
      `}
    >
      <Typography.Title
        level={3}
        className={css`
          display: flex;
          align-items: center;
        `}
      >
        {name}
      </Typography.Title>
    </div>

    <Tag
      title={`Gebaseerd op ${ratingCount} beoordelingen`}
      className={css`
        margin-left: 10px;
        font-weight: normal;
      `}
    >
      Gemiddelde beoordeling {ratingAverage.toFixed(1)}
    </Tag>
  </div>
);
