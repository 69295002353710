import { Form, Input, InputNumber } from "antd";
import { FormComponentProps } from "antd/lib/form";
import React from "react";
import { ProjectRatingType } from "types/project.types";

export const RatingDetailsFormKeys: {
  [formKey: string]: keyof ProjectRatingType;
} = {
  COMMENT: "ratingText",
  RATING: "ratingNumber",
  IMPORTANCE: "importanceNumber",
  CONTACT: "contact",
};

type RatingDetailsFormGroupProps = FormComponentProps & {
  rating: ProjectRatingType;
  onChangeHook?(): void;
};

export const RatingDetailsFormGroup: React.FC<RatingDetailsFormGroupProps> = ({
  form: { getFieldDecorator },
  rating,
  onChangeHook,
}) => (
  <>
    <Form.Item label="Reactie">
      {getFieldDecorator(RatingDetailsFormKeys.COMMENT, {
        initialValue: rating.ratingText,
      })(<Input placeholder="Reactie" />)}
    </Form.Item>
    <Form.Item label="Beoordeling">
      {getFieldDecorator(RatingDetailsFormKeys.RATING, {
        initialValue: rating.ratingNumber,
        rules: [{ required: false }],
      })(<InputNumber placeholder="Beoordeling" max={10} min={0} onChange={onChangeHook} />)}
    </Form.Item>
    <Form.Item label="Belangrijkheid">
      {getFieldDecorator(RatingDetailsFormKeys.IMPORTANCE, {
        initialValue: rating.importanceNumber,
        rules: [{ required: false }],
      })(<InputNumber placeholder="Belangrijkheid" max={10} min={0} onChange={onChangeHook} />)}
    </Form.Item>
  </>
);
