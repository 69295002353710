import React, { useState } from "react";
import { DeleteButton } from "components/molecules/buttons/delete-button";
import { api } from "api";

type DeleteRatingControllerProps = {
  ratingId: string;
  onDeleteSuccessful(): void;
};

export const DeleteRatingController: React.FC<DeleteRatingControllerProps> = ({
  ratingId,
  onDeleteSuccessful,
}) => {
  const [loading, setLoading] = useState(false);

  const onDeleteHandler = async () => {
    setLoading(true);
    api.deleteRating(ratingId).then(successful => {
      if (successful) {
        onDeleteSuccessful();
      }
      setLoading(false);
    });
  };

  return <DeleteButton onClick={onDeleteHandler} loading={loading} />;
};
