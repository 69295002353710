import { Table, Typography } from "antd";
import { ColumnProps, TableProps } from "antd/lib/table";
import { Moment } from "moment";
import React from "react";
import { css } from "emotion";
// @ts-ignore
import Truncate from "react-truncate";
import { ModalButton } from "./buttons/modal-button";

type ProductReviewType = {
  key: string;
  actions: React.ReactNode[];
  date: Moment;
  comment: React.ReactNode;
  experienceRating: number;
  importanceRating: number;
  response: React.ReactNode;
  contact: React.ReactNode;
};

const columns: ColumnProps<ProductReviewType>[] = [
  {
    key: "actions",
    title: "",
    dataIndex: "actions",
    width: 200,
  },
  {
    key: "user",
    title: "Gebruiker",
    dataIndex: "contact",
  },
  {
    key: "comments",
    title: "Opmerkingen",
    dataIndex: "comment",
    render: value =>
      value ? value : <Typography.Text disabled={true}>Niet opgegeven</Typography.Text>,
  },
  {
    key: "response",
    title: "Antwoord",
    dataIndex: "response",
    className: css`
      max-width: 300px;
    `,
  },
  {
    key: "experienceRating",
    title: "Beoordeling",
    dataIndex: "experienceRating",
  },
  {
    key: "importanceRating",
    title: "Belangrijkheid",
    dataIndex: "importanceRating",
    render: value =>
      value ? value : <Typography.Text disabled={true}>Niet opgegeven</Typography.Text>,
  },
  {
    key: "date",
    title: "Datum",
    dataIndex: "date",
    render: date => (date as Moment).format("YYYY/MM/DD HH:mm"),
  },
];

type ReviewsTableProps = TableProps<ProductReviewType>;

export const ReviewsTable: React.FC<ReviewsTableProps> = ({ ...props }) => (
  <Table columns={columns} {...props} />
);
