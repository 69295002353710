import React, { useState } from "react";
import { Button, Typography, Popover } from "antd";
import { css } from "emotion";

type DeleteButtonProps = {
  loading: boolean;
  onClick(): void;
};

export const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick, loading }) => {
  const [isVisible, setVisible] = useState(false);

  const onClickHandler = () => {
    setVisible(false);
    onClick();
  };

  const onVisibleChangeHandler = (visible: boolean) => {
    setVisible(visible);
  };

  return (
    <Popover
      content={
        <div
          className={css`
            display: flex;
            flex-direction: column;

            & > button {
              margin-top: 5px;
            }
          `}
        >
          <Typography.Text>
            Het verwijderen van een beoordeling kan niet ongedaan worden gemaakt
          </Typography.Text>
          <Button type="danger" onClick={onClickHandler} loading={loading}>
            Verwijderen
          </Button>
        </div>
      }
      title="Weet je het zeker?"
      trigger="click"
      visible={isVisible}
      onVisibleChange={onVisibleChangeHandler}
    >
      <a href="javascript:;">
        <Typography.Text type="danger">Verwijderen</Typography.Text>
      </a>
    </Popover>
  );
};
